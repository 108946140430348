.dark {
  filter: invert(95%) hue-rotate(180deg) grayscale(20%) contrast(100%) brightness(150%) saturate(140%);

  img, video, iframe, .flag-icon {
    filter: invert(95%) hue-rotate(180deg) contrast(0.7) brightness(130%) saturate(120%);
  }

  .logo-image {
    //filter: brightness(85%)
  }

  .b-sidebar .sidebar-content {
    box-shadow: none !important;
  }

  .tox-edit-area {
    filter: invert(100%) hue-rotate(180deg);
  }

  pre {
    filter: invert(6%);
  }

  li::marker {
    color: #666;
  }

  .user-wrapper img:not(.avatar-external)  {
      filter: invert(0%);
  }

  .vuetable {
    .is-relative {
      th.is-odd {
        color: black !important;
        background-color: rgba(221, 231, 255, 1) !important;
      }
      th.is-even {
        color: black !important;
        background-color: rgba(198, 214, 255, 1) !important;
      }
    }
    .table-total-row, .table-total-column  {
      background-color: rgba(212, 204, 229, 0.2) !important;
    }
  }

  .table thead tr {
    th.is-header.is-odd {
      color: black !important;
      background-color: rgba(221, 231, 255, 1) !important;
    }
    th.is-header.is-even {
      color: black !important;
      background-color: rgba(198, 214, 255, 1) !important;
    }
  }

  .is-active.external-item::before {
      background-color: rgba(26, 92, 255, 0.15) !important;
  }

  .trigger {
    &-wrapper {
      &:hover {
        a.external-item::before  {
          background-color: rgba(26, 92, 255, 0.15) !important;
        }
      }
    }
  }

  .rating-banner, .rating-banner_prizes-wrapper, .rating-card_prizes, .rating-banner_title {
    filter: invert(95%) hue-rotate(180deg) contrast(0.7) brightness(130%) saturate(120%);
  }

  .rating-banner_title, .rating-banner_subtitle img {
    filter: brightness(85%)
  }

  // FIXME: Для конкурса
  .competitionInfo {
    .dropdown-content {
      box-shadow: 0 0.5em 1em -0.125em rgba(85, 124, 3, 0.2), 0 0px 0 1px rgba(85, 124, 3, 0.02);
    }
  }

  .navbar.has-shadow {
    box-shadow: none;
  }

  .progressBar-wrapper {
    filter: invert(95%) hue-rotate(180deg) contrast(0.7) brightness(130%) saturate(120%);

  .dropdown-trigger, div, span {
      color: white
  }

    img, .flag-icon {
      filter: initial !important;
    }
  }

  //.vuetable-body > tr {
  //  & > td:first-child {
  //    &.has-background-warning-light {
  //      background-color: white !important;
  //    }
  //  }
  //}
}