
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        


































































































































































































































































































































































































































































@import "~bulma/sass/utilities/controls";

$button-padding: 0.9em;
//noinspection SassScssUnresolvedVariable
$button-padding-vertical: calc(#{$button-padding} - #{$control-border-width});
$button-padding-horizontal: $button-padding;

.control {
    &.input-disabled {
      ::v-deep {
        .vs__dropdown-toggle {
          cursor: not-allowed;
        }
      }
    }
    &.input-none-searchable {
      ::v-deep {
        .vs__dropdown-toggle {
          cursor: pointer;
        }
      }
    }
    &.input-searchable {
      ::v-deep {
        .vs__dropdown-toggle {
          cursor: text;
        }
      }
    }

    &::v-deep {
        .vs {
            $self: &;

            &__selected {
                background-color: #fafafa;
                border: 1px solid rgba(140, 140, 140, .26);

                .country-block {
                    justify-content: space-between;
                    align-items: center;
                }

                .country-flag {
                    position: absolute;
                    display: flex;
                    align-items: center;

                    .flag-icon {
                        width: 20px !important;
                        height: 20px !important;
                    }
                }

                .country-item {
                    margin-left: 1.5rem;
                }
            }

            &__spinner {
                font-size: 3px;
                border: .9em solid hsl(0deg 0% 100% / 10%);
                border-left-color: rgba(60,60,60,.45);
                animation: vSelectSpinner .7s linear infinite;
                margin-right: 5px;
            }

            &__search::placeholder,
            &__dropdown-toggle,
            &__dropdown-menu {
                font-family: inherit;
                border-radius: $radius;
                padding: 6px 0;
            }

            &__search {
                padding: 0 4px;

                &::placeholder {
                    //noinspection SassScssResolvedByNameOnly
                    font-weight: $placeholder-font-weight;
                    //noinspection SassScssResolvedByNameOnly
                    color: $placeholder-color;
                }

                &::-webkit-input-placeholder {
                    //noinspection SassScssResolvedByNameOnly
                    font-weight: $placeholder-font-weight;
                    //noinspection SassScssResolvedByNameOnly
                    color: $placeholder-color;
                }

                &::-ms-input-placeholder {
                    //noinspection SassScssResolvedByNameOnly
                    font-weight: $placeholder-font-weight;
                    //noinspection SassScssResolvedByNameOnly
                    color: $placeholder-color;
                }
            }

            &__clear,
            &__open-indicator {
                fill: #dbdbdb;
            }
        }

        .vs__dropdown-toggle {
            border-color: #dbdbdb;
        }

        &:hover {
            .vs__dropdown-toggle {
                //noinspection SassScssResolvedByNameOnly
                border: 1px solid $light;
            }
        }

        .vs__selected-options {
            input {
                font-size: 0.875rem;

                &[disabled="disabled"] {
                    background-color: transparent;
                }
            }
        }
        &.not-empty {
            .vs__selected-options {
                margin-top: -4px;
                padding: 0 4px;
                min-height: 25px; // предотвращает уменьшение высоты селекта при открытии
            }
        }

        &.vs {
            &--open {
                .vs__dropdown-toggle {
                    border-color: $primary;
                }
            }

            &--disabled {
                .vs__dropdown-toggle {
                    background-color: #f8f8f8 !important;
                    cursor: not-allowed;
                }

                .vs__actions {
                    * {
                        display: none;
                    }
                    .vs__spinner {
                        display: flex;
                    }
                }
            }

            &--single {
                .vs__selected {
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }

        &.is-opened-top {
            top: auto;
            bottom: 100%;
        }

        &.white {
            .vs__dropdown-toggle {
                background-color: #fff;
            }
        }

        &.fixed {
            .vs__selected-options {
                white-space: pre;
                overflow: hidden;
                flex-wrap: unset;
            }

            .vs__selected {
                position: absolute;
                left: 0;
            }
        }
    }
}

.marquee {
    @media (min-width: 800px) {
        &:hover {
            animation: marquee 5s linear infinite;
        }
        @keyframes marquee {
            0% {
                transform: translateX(0%);
            }
            50% {
                //noinspection CssInvalidFunction
                transform: translateX(calc(-100% + var(--translate-element-width)));
            }
            100% {
                transform: translateX(0%);
            }
        }
    }
}
